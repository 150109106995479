// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  CLIENTE: '/cliente',
  PUBLICO: '/publico',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`
    },
    cambiar_clave: {
      root: `${ROOTS.AUTH}/cambiar-clave`
    },
    solicitud_cambiar_clave: `${ROOTS.AUTH}/solicitud-cambio-clave`
  },
  publico: {
    root: ROOTS.PUBLICO,
    inscripcion: {
      root:`${ROOTS.PUBLICO}/inscripciones`
    }
  },
  cliente: {
    root: ROOTS.CLIENTE,
    inscripciones: {
      root:`${ROOTS.CLIENTE}/inscripciones`,
      detalle: (numero) => `${ROOTS.CLIENTE}/inscripciones/${numero}`,
      registro: `${ROOTS.CLIENTE}/inscripciones/registro`
    },
    cursos: {
      root:`${ROOTS.CLIENTE}/cursos`,
      detalle: (identificacion) => `${ROOTS.CLIENTE}/cursos/${identificacion}`,
    },
    cartera: {
      root:`${ROOTS.CLIENTE}/cartera`,
      facturacion: {
        detalle: (numero) => `${ROOTS.CLIENTE}/cartera/factura/${numero}`,
      },
    }
  } 
};