import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard/layout";
import CompactLayout from 'src/layouts/compact';

// components
import { LoadingScreen } from "src/components/loading-screen";
// ----------------------------------------------------------------------

const TableroPage = lazy(() => import("src/pages/cliente/tablero"));

// // CURSOS
const PageCursos = lazy(() => import('src/pages/cliente/cursos/lista'));
const DetalleCursoPage = lazy(() => import('src/pages/cliente/cursos/detalle'));

// INSCRIPCIONES
const ListaInscripcionesPage = lazy(() =>
  import("src/pages/cliente/inscripciones/lista")
);
const NuevaInscripcionPage = lazy(() =>
  import("src/pages/cliente/inscripciones/nuevo")
);

const DetalleInscripcionPage = lazy(() =>
  import("src/pages/cliente/inscripciones/detalle")
);

// // FACTURACION
const DetalleFacturacionPage = lazy(() => import('src/pages/cliente/cartera/factura/detalle'));

// // PAGOS
const PagePagos = lazy(() => import('src/pages/cliente/cartera/lista'));

const CambiarClavePage = lazy(() => import('src/pages/auth/cambio-clave'));

// ----------------------------------------------------------------------

export const clienteRoutes = [
  {
    path: 'cambiar-clave',
    element: (
      <CompactLayout>
        <CambiarClavePage />
      </CompactLayout>
    ),
  },
  {
    path: "cliente",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <TableroPage />, index: true },
      {
        path: "inscripciones",
        children: [
          { element: <ListaInscripcionesPage />, index: true },
          { path: ":numero", element: <DetalleInscripcionPage /> },
          { path: "registro", element: <NuevaInscripcionPage /> },
        ],
      },
      {
        path: 'cursos',
        children: [
          { element: <PageCursos />, index: true },
          { path: ':identificacion', element: <DetalleCursoPage /> }
        ]
      },
      {
        path: 'cartera',
        children: [
          { element: <PagePagos />, index: true },
          {
            path: 'factura',
            children: [
              { path: ':numero', element: <DetalleFacturacionPage /> },
            ]
          }
        ]
      }
    ],
  },
];

