import axios from "axios";
// config
import { HOST_API } from "src/config-global";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: "/api/auth/me",
    login: "/api/auth/login",
    register: "/api/auth/register",
  },
  cliente: {
    balance: (idcliente) => `/api/cliente/${idcliente}/balance`,
    movimientos: (idcliente) => `/api/cliente/${idcliente}/movimientos`,
    detalle: (idcliente) => `/api/cliente/${idcliente}`,
    cambiar_clave: (idcliente) => `/api/cliente/${idcliente}/perfil/cambio-clave`,
    solicitud_cambio_clave: (idcliente) => `/api/cliente/${idcliente}/perfil/solicitud-cambio-clave`
  },
  inscripciones: {
    lista: (idcliente) => `/api/cliente/${idcliente}/inscripciones`,
    detalle: (idcliente, numero) => `/api/cliente/${idcliente}/inscripciones/${numero}`,
    anular: (idcliente, numero) => `/api/cliente/${idcliente}/inscripciones/${numero}/anular`,
    registro: (idcliente) => `/api/cliente/${idcliente}/inscripciones`,
    enlaces: (idcliente, numero) => `/api/cliente/${idcliente}/inscripciones/${numero}/enlaces`,
  },
  cursos: {
    lista: (idcliente) => `/api/cliente/${idcliente}/cursos`,
    detalle: (idcliente, identificacion) => `/api/cliente/${idcliente}/cursos/${identificacion}`
  },
  usuarios: {
    detalle: (idcliente, identificacion) => `/api/cliente/${idcliente}/usuarios/${identificacion}`,
  },
  facturas:{
    lista:  (idcliente) => `/api/cliente/${idcliente}/facturas`,
    detalle: (idcliente, numero) => `/api/cliente/${idcliente}/facturas/${numero}`,
    enlaces: ''
  }, 
  pagos: {
    lista:  (idcliente) => `/api/cliente/${idcliente}/pagos`,
    detalle: '',
    anular: '',
  },
  enlaces: {
    generar: (idcliente) => `/api/cliente/${idcliente}/enlaces`,
  }
};
