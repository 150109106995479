import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import PublicaLayout from 'src/layouts/publica';

// components
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

const InscripcionRegistroPage = lazy(() => import('src/pages/publico/inscripciones/registro'))

// ----------------------------------------------------------------------

export const publicoRoutes = [
  {
    path: 'publico',
    element: (
      <PublicaLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </PublicaLayout>
    ),
    children: [
      {
        path: 'inscripciones',
        element: <InscripcionRegistroPage />, index: true
      }
    ]
  }
];
