import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';

//
import { HeaderSimple as Header } from '../_common';
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

export default function PublicaLayout({ children }) {
  const lgUp = useResponsive('up', 'lg');
  const settings = useSettingsContext();


  return (
    <>
       <Header />
       <Container>
        <Stack
          sx={{
            py: 12,
            m: 'auto'
          }}
          >
          {children}
        </Stack>
      </Container> 
          </>
  );
}

PublicaLayout.propTypes = {
  children: PropTypes.node,
};
